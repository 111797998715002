<template>
  <a class="tooltip-outer d-flex justify-content-center align-items-center mr-1 pointer">
    <div class="relative" v-if="!ttsUrl">
      <img src="@/assets/image/ai/btn_AItalk_play_loading_circle.png">
      <img src="@/assets/image/ai/loading_circle.png" class="absolute loading_circle">
    </div>
    <img src="@/assets/image/ai/btn_AItalk_play.png" v-if="ttsUrl && !isPlayingAudio" @click="audio_play()">
    <img src="@/assets/image/ai/btn_AItalk_darkblue.png" v-if="ttsUrl && isPlayingAudio" @click="audio_stop()">
    <span class="tooltiptext">播放原音</span>
  </a>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ChatPractice',
  props: {
    itemIdx: {
      type: Number,
      require: true
    },
    ttsUrl: {
      type: String
    }
  },
  data () {
    return {
      isRocord: false,
      mediaRecorder: null,
      audioData: [],

      sound_effect: new Audio(this.ttsUrl),
      isPlayingAudio: false
    }
  },
  computed: {
    ...mapGetters([
      'playbackRate'
    ])
  },
  watch: {
    'playbackRate': {
      handler (newVal, oldVal) {
        let self = this
        if (newVal !== oldVal) {
          self.audio_setRate()
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.audio_play()
  },
  methods: {
    audio_play () {
      this.isPlayingAudio = true
      this.sound_effect.play()
      this.sound_effect.playbackRate = this.playbackRate
      this.sound_effect.addEventListener('ended', () => {
        this.audio_stop()
      })
    },
    audio_stop () {
      this.isPlayingAudio = false
      this.sound_effect.pause()
      this.sound_effect.currentTime = 0
    },
    audio_setRate () {
      let self = this
      self.sound_effect ? self.sound_effect.playbackRate = self.playbackRate : null
    }
  }
}
</script>
<style lang="scss" scoped>
  .loading_circle {
    top: 15%;
    left: 15%;
    transform: translate(-50%, -50%);
    animation: spin 2s infinite linear;
    transform-origin: center;
  }
  @keyframes spin  {
    from {
      transform: rotate(0deg);
      transform-origin: center;
    }
    to {
      transform: rotate(360deg);
      transform-origin: center;
    }
  }
  .opacity-50{
    opacity: 0.5;
  }
</style>
