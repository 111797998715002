<template>
  <div class="w-100 d-flex justify-content-end" :class="{ 'selfDialogue': sentenceSpliter.length < 30 && selectedStep === 1, 'typing_height': sentenceSpliter.length > 29 && selectedStep === 1 }">
    <div class="chat-content-quote">
      <div class="chat-content-quote-text p-3">
        <!-- <p>{{ itemData.text }}</p> -->
        <div class="results_en">
          <p class="m-0 p-0 noto-serif-jp-ai">{{ tidyText }}</p>
        </div>

        <p v-if="selectedStep === 1 && grammarSugges && isOpenGrammar" class="tips-suggestion mt-2 pt-2 font-variant-east-asian-traditional">
          {{ grammarSugges }}
        </p>
        <p v-if="selectedStep === 1 && isOpenSimilar && isOpenSimilar" class="tips-suggestion mt-2 pt-2">
          {{ similarSentences }}
        </p>
      </div>
      <div class="robot-btns d-flex align-items-center mt-2 justify-content-end" v-if="selectedStep === 1">
        <a class="d-flex justify-content-center align-items-center mr-1 pointer hover-item tooltip-outer" v-if="chatHistory && chatHistory.audio" @click="audio_play()">
          <img src="@/assets/image/ai/btn_AItalk_voice.png" class="mr-1 off">
          <img src="@/assets/image/ai/btn_AItalk_voice_active.png" class="mr-1 on">
          <span class="tooltiptext">我的錄音</span>
        </a>
        <a v-if="grammarSugges" @click="toggleDirections('grammar')" class="tooltip-outer d-flex justify-content-center align-items-center mr-1 pointer">
          <img v-if="!isOpenGrammar" src="@/assets/image/ai/btn_AItalk_correct.png">
          <img v-if="isOpenGrammar" src="@/assets/image/ai/btn_AItalk_correct_active.png">
          <span class="tooltiptext">更多說明</span>
        </a>
        <!-- <a v-if="similarSentences" @click="toggleDirections('similar')" class="tooltip-outer d-flex justify-content-center align-items-center pointer">
          <img v-if="!isOpenSimilar" src="@/assets/image/ai/btn_AItalk_resemblance.png">
          <img v-if="isOpenSimilar" src="@/assets/image/ai/btn_AItalk_resemblance_active.png">
          <span class="tooltiptext">換句話說</span>
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/store/api'
import { mapGetters } from 'vuex'
// import dictApi from '@/store/library/api'
export default {
  name: 'selfDialogue',
  props: {
    itemData: {
      type: Object
    },
    selectedStep: {
      type: Number
    },
    theme: {
      type: String
    },
    context: {
      type: String
    },
    chatHistory: {
      type: Object
    }
  },
  data () {
    return {
      ttsUrlSelf: null,

      grammarSugges: null,
      similarSentences: null,

      isOpenGrammar: false,
      isOpenSimilar: false,
      isDictionary: true,
      isFinishTyped: false,

      sound_effect: null
    }
  },
  watch: {
    'chatHistory': {
      handler (newVal, oldVal) {
        let self = this
        if (newVal !== oldVal && newVal) {
          self.sound_effect = new Audio(self.chatHistory.audio)
        }
      },
      immediate: true
    },
    'playbackRate': {
      handler (newVal, oldVal) {
        let self = this
        if (newVal !== oldVal) {
          self.audio_setRate()
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters([
      'playbackRate'
    ]),
    tidyText () {
      return this.itemData.text.replace(/(?:\r\n|\r|\n)/g, '<br/>')
    },
    sentenceSpliter () {
      let self = this
      if (self.tidyText) {
        const str = self.tidyText
        let pattern = /([\n\s\p{P}])/u
        const splitStr = str.split(pattern)
        return splitStr
      }
      return []
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      let self = this
      if (self.itemData && self.itemData.text && self.selectedStep === 1) {
        self.grammar_suggestions(self.itemData.text)
        // self.getSimilarSentences(self.itemData.text)
      }
    },

    grammar_suggestions (text) {
      let self = this

      let path = `/ai/generate/grammar_suggestions`
      const options = { headers: {'showLoading': false }}
      api.post(path, {
        theme: self.theme,
        context: self.context,
        content: text
      }, options)
      .then((res) => {
        self.$store.dispatch('getRemainPoints')
        self.grammarSugges = res.data.result
      })
      .catch(() => {
      })
    },

    getSimilarSentences (text) {
      let self = this

      let path = `/ai/generate/reword`
      const options = { headers: {'showLoading': false }}
      api.post(path, {
        content: text
      }, options)
      .then((res) => {
        self.$store.dispatch('getRemainPoints')
        self.similarSentences = res.data.result
      })
      .catch(() => {
      })
    },

    toggleDirections (type) {
      let self = this
      if (type === 'grammar') {
        self.isOpenGrammar = !self.isOpenGrammar
        self.isOpenSimilar = false
      }
      if (type === 'similar') {
        self.isOpenSimilar = !self.isOpenSimilar
        self.isOpenGrammar = false
      }
    },

    judgeGrammarCorrect (result) {
      if (result.indexOf('更正句子') > -1 || result.indexOf('修正句子') > -1) {
        return true
      }
      return false
    },

    closeDict () {
      let self = this
      self.ductWord = null
      self.dictIdx = -1
    },


    isRegular (val) {
      // eslint-disable-next-line no-useless-escape
      let pattern = new RegExp(/[\p{P}\s]/u)
      if (val && val.match(pattern)) {
        return true
      }
      return false
    },
    audio_play () {
      this.sound_effect.play()
    },
    audio_stop () {
      this.sound_effect.pause()
      this.sound_effect.currentTime = 0
    },
    audio_setRate () {
      let self = this
      self.sound_effect ? self.sound_effect.playbackRate = self.playbackRate : null
    }
  }
}
</script>
<style lang="scss" scoped>
  .selfDialogue {
    min-height: 104px;
  }
  .typing_height {
    min-height: 128px;
  }
  .hover-item {
    .on {
      display: none;
    }
    &:hover {
      .off {
        display: none;
      }
      .on {
        display: block;
      }
    }
  }
  .results_en {
    p {
      white-space: pre-line;
      word-break: normal;
      margin-bottom: 0;
      span {
        position: relative;
      }
      .dict_chat {
        left: auto !important;
        right: 0 !important;
      }
      .isDictionary {
        &:hover {
          background-color: #BBDDFF;
        }
      }
      .active {
        background-color: #BBDDFF;
      }
    }
  }
  .chat-content-quote {
    font-weight: 700;
    max-width: 55%;
    @media (max-width: 768px) {
      max-width: 85%;
    }
    .chat-content-quote-text {
      background-color: #FFF3DD;
      border-bottom-left-radius: 20px 20px;
      border-top-left-radius: 20px 20px;
      border-top-right-radius: 20px 20px;
      .option-item {
        width: 25%;
        text-align: center;
      }
      .tips-suggestion {
        border-top: 1px solid #E28049;
        border-style: dashed none none;
        color: #E28049;
      }
    }
  }
</style>
